import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Message } from 'element-ui'
import i18n from "@/tools/i18n.js";
const state = {
  token: getToken(),
  name: '',
  avatar: '',
  info: {},
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_INFO: (state, info) => {
    state.info = info
  },
}

const actions = {
  // get user info
  getInfo({commit}) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const {Data} = response
        if (!Data) {
          reject('Verification failed, please Login again.')
        }
        const locked = Data.locked
        if(locked){
          logout(state.token).then(() => {
            commit('SET_TOKEN', '')
            commit('SET_INFO', '')
            removeToken()
            resolve()
          }).catch(error => {
            reject(error)
          })
        }else{
          // let language = Data.language == "" || Data.language == null ? "en" : Data.language
          // window.localStorage.setItem("lang", language)
          // i18n.locale = language
          commit('SET_INFO', Data)
          resolve(Data)
        }
      }).catch(error => {
        // reject(error)
      })
    })
  },
  // user login
  login({ commit }, userInfo) {
    const { username, password, prefix } = userInfo
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        const { Data } = response
        commit('SET_TOKEN', Data)
        setToken(Data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_INFO', '')
        removeToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
