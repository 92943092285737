import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: () => import('@/views/my/deposit'),
  },
  {
    path: '/missions',
    name: 'missions',
    component: () => import('@/views/my/missions'),
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('@/views/order/record'),
  },
  {
    path: '/record/details',
    name: 'record',
    component: () => import('@/views/order/details'),
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/my'),
  },
  {
    path: '/my/setup',
    name: 'my',
    component: () => import('@/views/my/setup'),
  },
  {
    path: '/my/editname',
    name: 'my',
    component: () => import('@/views/my/editname'),
  },
  {
    path: '/my/editemail',
    name: 'my',
    component: () => import('@/views/my/editemail'),
  },
  {
    path: '/my/bankCard',
    name: 'my',
    component: () => import('@/views/my/bankCard'),
  },
  {
    path: '/my/addbankcard',
    name: 'my',
    component: () => import('@/views/my/addbankcard'),
  },
  {
    path: '/my/changepassword',
    name: 'my',
    component: () => import('@/views/my/changepassword'),
  },
  {
    path: '/my/changepaymethodpsd',
    name: 'my',
    component: () => import('@/views/my/changepaymethodpsd'),
  },
  {
    path: '/my/withdraw',
    name: 'my',
    component: () => import('@/views/my/withdraw'),
  },
  {
    path: '/my/choosebankaccount',
    name: 'my',
    component: () => import('@/views/my/choosebankaccount'),
  },
  {
    path: '/my/message',
    name: 'my',
    component: () => import('@/views/my/message'),
  },
  {
    path: '/my/depositrecord',
    name: 'my',
    component: () => import('@/views/my/depositrecord'),
  },
  {
    path: '/my/withdrawrecoed',
    name: 'my',
    component: () => import('@/views/my/withdrawrecoed'),
  },
  {
    path: '/my/article',
    name: 'my',
    component: () => import('@/views/my/article'),
  },
  {
    path: '/my/team',
    name: 'my',
    component: () => import('@/views/my/team'),
  },
  {
    path: '/my/invite',
    name: 'my',
    component: () => import('@/views/my/invite'),
  },
  {
    path: '/my/language',
    name: 'language',
    component: () => import('@/views/my/language'),
  },
  {
    path: '/about/messagedetails',
    name: 'my',
    component: () => import('@/views/about/messagedetails'),
  },
]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}

const router = new VueRouter({
  routes
})

export default router
