<template>
  <router-view></router-view>
</template>
<script>
export default {


  mounted() {

  },
  methods: {

  }

}
</script>
<style lang="less">
html {}

body {

  .pageBg,
  .rv-pull-refresh__track {
    min-height: 100vh;
  }

  .pageBg {
    background-image: url(/static/image/top_bg.svg);
    background-size: auto 100%;
    background-size: contain;
    background-repeat: no-repeat;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  background-color: #f5f6f7;
  min-height: 100vh;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--font-icon-t3)
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background: var(--font-icon-t3)
  }

  ::-webkit-scrollbar-track {
    background: var(--el-color-white)
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-radius: 50%;
    // background-color: var(--color-theme-main);
    // border-color: var(--color-theme-main);
  }

  .el-checkbox__inner {
    border-radius: 50%;
  }



  .el-select-dropdown {

    .el-select-dropdown__item {
      // font-size: 24px !important;
      // height: 60px;
      // line-height: 60px;
    }

    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      color: var(--color-theme-main) !important;
      background-color: rgba(255, 255, 255, 0.2) !important;
    }

  }

  .el-form-item {
    margin-bottom: 10px;
    // margin-bottom: 0;
  }

  // .el-button {
  //   background-color: var(--bg-theme-main);
  //   height: 45px;
  //   color: #FFF;
  //   width: 100%;
  //   border-radius: 10px;
  //   font-size: 18px;
  // }
  .el-message-box {
    width: 80%;
  }

}

.service {
  position: fixed;
  right: 25px;
  bottom: 30vh;
  z-index: 10;
  border-radius: 50%;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  background-color: #1f2a47;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 18px;
  }
}</style>
