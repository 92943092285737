export default {
    common: {
        rechargePic: "Recharger voucher",
        language: "Sprache",
        walletAddress: "Wallet-Adresse",
        walletType: "Wallet-Typ",
        addAddress: "Adresse hinzufügen",
        chooseAddress: "Adresse auswählen",
        withdrawAddress: "Adresse abheben",
        levelEight: "Einfrieren des Bankkartenkanals",
        selectBank: "Bitte wählen Sie ein Bankkonto aus",
        freezeMessage: "Bitte wenden Sie sich an Ihren Vorgesetzten",
        completeOrder: "Sind Sie sicher, dass Sie diese Bestellung abschließen möchten?",
        logout: "Sind Sie sicher, dass Sie sich abmelden möchten?",
        currency: "USDT",
        deposit: "Kaution",
        withdraw: "Rückzug",
        invite: "Einladen",
        balance: "Saldo",
        app_name: "Zalando",
        deposit_successful: "Einzahlung erfolgreich",
        withdraw_successful: "Auszug erfolgreich",
        deposit_fail: "Einzahlung fehlgeschlagen",
        save: "Speichern",
        details: "Details",
        add: "Hinzufügen",
        confirm: "Bestätigen",
        remind: "Erinnern",
        success: "Erfolg",
        cancel: "Abbrechen",
        description: "Beschreibung",
        copy_success: "Erfolgreich kopiert!",
        copy_fail: "Kopieren fehlgeschlagen!",
        submit: "Absenden",
        no_record: "Kein Datensatz",
		countryCode: "Ländercode",
        withdraw0: "ANWENDUNG",
        withdraw1: "PENDING",
        withdraw2: "ERFOLGE",
        withdraw3: "FEHLERT",
        orderNow: "Jetzt bestellen",
        noBalance: "Der verfügbare Kontostand ist unzureichend !",
        needTo: "Der verfügbare Kontostand ist unzureichend! Sie müssen aufladen ",
    },
    home: {
        notice_tit: "Mitteilung",
        notice_cont: "Willkommen auf der kostenlosen Shoppingplattform Groupon Mall und vielen Dank für Ihre Teilnahme. Je höher das VIP-Level der Plattform, desto höher ist die Anzahl der Warenaufträge und desto höher der Provisionsbetrag, denn Sie können leicht eine großzügige Provision von 100.000 Rupien pro Monat verdienen.",
        today_profit: "Der heutige Gewinn",
        yesterday_profit: "Der gestrige Gewinn",
        accumulated_profit: "Kumulierter Gewinn",
        historical_deposits: "Historische Benutzereinlagen",
        historical_withdraw: "Historischer Benutzer-Auszug",
        about_investment_income: "Über Investitionserträge",
        notice: "Bekanntmachung",
        about_us: "Über uns",
        company_qualification: "Unternehmensqualifikation",
        cooperative_partner: "Kooperationspartner",
    },
    login: {
        login: "Login",
        register: "Registrieren",
        remember: "Passwort für das Konto speichern",
        top: "Code abrufen",
        complete_register: "Vollständige Registrierung",
    },
    form: {
        label_username: "Benutzername",
        place_username: "Bitte geben Sie die Telefonnummer ein",
        label_password: "Passwort",
        place_password: "Bitte Passwort eingeben",
        label_phone: "Telefonnummer",
        place_phone: "Bitte geben Sie die Telefonnummer ein",
        label_verifycode: "Prüfcode",
        place_verifycode: "Prüfcode",
        label_setpassword: "Login Passwort festlegen",
        label_crmpassword: "Login Passwort bestätigen",
        place_setpassword: "Login Passwort bestätigen",
        label_invitecode: "Einladungscode",
        place_invitecode: "Einladungscode",
        label_realname: "Real Name",
        place_realname: "Bitte geben Sie echten Namen ein",
        label_telephone: "Telefon",
        place_telephone: "Bitte geben Sie Telefon ein",
        label_bankname: "Name der Bank",
        place_bankname: "Bitte geben Sie den Banknamen ein",
        label_banknumber: "Bankkontonummer",
        place_banknumber: "Bitte geben Sie Bankkontonummer ein",
        label_ifsc: "IFSC",
        place_ifsc: "Bitte IFSC eingeben",
        label_oldpassword: "Altes Passwort",
        label_newpassword: "Neues Passwort",
        message_amount: "Bitte geben Sie den Betrag ein",
        place_deposit_amount: "Einzahlungsbetrag",
        place_withdraw_amount: "Betrag eingeben",
        place_payment_channel: "Bitte Zahlungskanal eingeben",
        label_email: "E-mail",
        place_email: "Bitte E-Mail eingeben",
        label_actualname: "Tatsächlicher Name",
        place_actualname: "Bitte geben Sie den tatsächlichen Namen ein",
    },
    my: {
        add_bank: "Bankkontonummer hinzufügen",
        bank_account: "Bankkonto",
        change_password: "Passwort ändern",
        modify_pay_password: "Zahlungspasswort ändern",
        choose_bank: "Wählen Sie Bankkonto",
        deposit_record: "Einlagenaufzeichnung",
        withdraw_record: "Auszahlungsdatum",
        edit_email: "E-Mail bearbeiten",
        edit_name: "Tatsächlicher Name bearbeiten",
        invite: "Einladen",
        invite_code: "Einladungscode",
        copy_link: "Link kopieren",
        message: "Nachricht",
        personal_center: "Persönliches Zentrum",
        order_list: "Bestellliste",
        team_report: "Teambericht",
    },
    deposit: {
        deposit_amount: "Einzahlungsbetrag",
        account_balance: "Kontostand",
        payment_channel: "Zahlungskanal",
        tip1: "1. Die Adresse der Plattform Wallet ändert sich jederzeit. Bitte geben Sie die Ladeoberfläche ein, um die neuesten Informationen über die Wallet-Adresse jeder Einzahlung zu erhalten.",
        tip2: "2.Bitte wenden Sie sich bei Problemen an Ihren Kundenberater.",
        methodDrawer_tit: "Bitte wählen Sie die begünstigte Bank aus",
    },
    missions: {
        order_hall: "Ordensaal",
        commission: "Kommission",
        account_balance: "Kontostand",
        commission_today: "Kommission heute",
        total_commission: "Kommission insgesamt",
        completed_orders: "Abgeschlossene Aufträge",
        locked_orders: "Gesperrte Aufträge",
        unfinished_tasks: "Nicht abgeschlossene Aufgaben",
        frozen_amount: "Gefrorene Menge",
        my_team: "Mein Team",
        order: "Reihenfolge",
        match_successfully: "Übereinstimmung erfolgreich",
        tip1: "1.Ihre Mitgliedsstufe kann 15-Einzelmissionen entsprechen.",
        tip2: "2. Wenn die Bestellung nicht innerhalb von 60.00 Minuten eingereicht wird, friert das System den Bestellbetrag ein. Bitte kontaktieren Sie den Kundenagenten so schnell wie möglich, um sich mit der Lösung zu befassen.",
    },
    setup: {
        set_up: "Einrichten",
        user_id: "Benutzer-ID",
        my_name: "Mein Name",
        actual_name: "Tatsächlicher Name",
        email: "E-mail",
        my_bank_account_number: "Meine Wallet-Adresse",
        change_password: "Passwort ändern",
        set_modify_payment_password: "Setzen Zahlungspasswort ändern",
    },
    team: {
        team_report: "Teambericht",
        to: "An",
        searchFor: "Suche nach",
        team_balance: "Teambalance",
        total_team_recharge: "Gesamte Teamaufladung",
        team_commission: "Team Kommission",
        team_flow: "Team Flow",
        total_team_Withdrawal: "Auszug des Teams insgesamt",
        number_of_team_members: "Anzahl der Teammitglieder",
        account_name: "Kontoname",
        recommended_number: "Empfohlene Zahl",
        registrationTime: "registrationTime",
    },
    withdraw: {
        withdraw_amount: "Auszahlungsbetrag",
        all: "Alle zurückziehen",
        withdraw_bank: "Kontonummer für Auszahlungen",
        tip1: "①Der minimale Auszahlungsbetrag beträgt 10 oder mehr, bevor die Auszahlung erfolgreich sein kann.",
        tip2: "②5% Gebühr, die von jeder Auszahlung abgezogen wird.",
        tip3: "③Nach Abschluss des Auszahlungsantrags wird die Plattform fortfahren, bitte warten Sie geduldig.",
    },
    order: {
        order_details: "Bestelldetails",
        order_freeze: "Order Freeze Countdown",
        product_information: "Produktinformationen",
        tip: "R@DIANT Kronleuchter mit 3-Leuchten, Würfel (schwarz) Marke R@DIANT",
        order_amount: "Auftragsbetrag",
        order_information: "Bestellinformationen",
        get_time: "Zeit haben",
        payment_time: "Zahlungsfrist",
        order_number: "Bestellnummer",
        rebate_information: "Rabattinformationen",
        rebate_ratio: "Rabattverhältnis",
        rebate_amount: "Rabattbetrag",
        expected_profit: "Erwarteter Gewinn",
        order_record: "Auftragsdatensatz",
        in_progress: "In Bearbeitung",
        frozen: "gefroren",
        completed: "Abgeschlossen",
        order_freeze_countdown: "Order Freeze Countdown",
        unfreeze: "Einfrieren",
    },
    city: {
        albania: "Albanien",
        algeria: "Algerien",
        argentina: "Argentinien",
        armenia: "Armenien",
        australia: "Australien",
        pakistan: "Pakistan",
        austria: "Österreich",
        bahrain: "Bahrain",
        belgium: "Belgien",
        bosnia_and_Herzegovina: "Bosnien und Herzegowina",
        brazil: "Brasilien",
        brunei: "Brunei",
        bulgaria: "Bulgarien",
        cambodia: "Kambodscha",
        canada: "Kanada",
        cameroon: "Kamerun",
        chile: "Chile",
        colombia: "Kolumbien",
        costa_Rica: "Costa Rica",
        croatia: "Kroatien",
        cyprus: "Zypern",
        czech_Republic: "Tschechische Republik",
        denmark: "Dänemark",
        dominican_Republic: "Dominikanische Republik",
        egypt: "Ägypten",
        estonia: "Estland",
        ethiopia: "Äthiopien",
        finland: "Finnland",
        france: "Frankreich",
        georgia: "Georgien",
        germany: "Deutschland",
        ghana: "Ghana",
        greece: "Griechenland",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungary",
        iceland: "Iceland",
        ireland: "Irland",
        italy: "Italien",
        india: "Indien",
        indonesia: "Indonesien",
        israel: "Israel",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japan",
        kazakstan: "Kazakhstan",
        kenya: "Kenia",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirgisistan",
        laos: "Laos",
        latvia: "Lettland",
        lithuania: "Litauen",
        luxembourg: "Luxemburg",
        macao_China: "Macao_China",
        macedonia: "Mazedonien",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexiko",
        moldova: "Moldawien",
        monaco: "Monaco",
        mongolia: "Mongolei",
        montenegro: "Montenegro",
        morocco: "Marokko",
        myanmar: "Myanmar",
        netherlands: "Niederlande",
        new_Zealand: " Neuseeland",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norwegisch",
        oman: "Oman",
        palestine: "Palästina",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippinen",
        poland: "Polen",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Katar",
        romania: "Rumänien",
        russia: "Russland",
        republic_of_Trinidad_and_Tobago: "Republik Trinidad und Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Saudi Arabien",
        serbia: "Serbien",
        singapore: "Singapur",
        slovakia: "Slowakei",
        slovenia: "Slowenien",
        south_Africa: "Südafrika",
        spain: "Spanien",
        sri_Lanka: "Sri Lanka",
        sweden: "Schweden",
        switzerland: "Schweiz",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tadschikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "Türkei",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Vereinigte Arabische Emirate",
        united_Kingdom: "Vereinigtes Königreich",
        united_States: " United_States",
        uzbekistan: "Usbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Aserbaidschan",
        bangladesh: "Bangladesch",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivien",
        botswana: "Botswana",
        british_Virgin_Islands: "Britische Jungferninseln",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Kap Verde",
        cayman_Islands: "Kaimaninseln",
        central_African_Republic: "Zentralafrikanische Republik",
        chad: "Tschad",
        comoros: "Komoren",
        the_Republic_of_the_Congo: " Die_Republik_Kongo",
        democratic_Republic_of_the_Congo: "Demokratische Republik Kongo",
        djibouti: "Dschibuti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Äquatorialguinea",
        eritrea: "Eritrea",
        fiji: "Fidschi",
        gabon: "Gabun",
        gambia: "Gambia",
        greenland: "Grönland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Insel_of_Man",
        cote_d_Ivoire: "Elfenbeinküste",
        jamaica: "Jamaika",
        jordan: "Jordan",
        lebanon: "Libanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libyen",
        madagascar: "Madagaskar",
        malawi: "Malawi",
        maldives: "Malediven",
        mali: "Mali",
        mauritania: "Mauretanien",
        mauritius: "Mauritius",
        mozambique: "Mosambik",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republik_Niger",
        north_Korea: " Nordkorea",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syrien",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunesien",
        united_States_Virgin_Islands: "Amerikanische Jungferninseln",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatikan",
        yemen: "Jemen",
        yugoslavia: "Jugoslawien",
        zambia: "Sambia",
        zimbabwe: "Simbabwe",
        china: "China",
     }



};
