export default function lang() {
    let lang = navigator.language; //浏览器语言判断
    lang = lang.substr(0, 2);
    switch (lang) {
        case "en": //英文
            lang = "en";
            break;
        case "es": //英文
            lang = "es";
            break;
        case "pt": //英文
            lang = "pt";
            break;
        case "de": //英文
            lang = "de";
            break;

        default:
            lang = "en";
    }
    return lang;
}
