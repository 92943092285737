export default {
    common: {
        rechargePic: "Recharger voucher",
        completeOrder: "¿Está seguro de que desea completar este pedido?",
        logout: "¿Seguro que quieres cerrar la sesión?",
        currency: "USDT",
        deposit: "Depósito",
        withdraw: "Retirar",
        invite: "Invite a",
        balance: "Saldo",
        app_name: "Zalando",
        deposit_successful: "Depósito efectuado con éxito",
        withdraw_successful: "Retirada con éxito",
        deposit_fail: "Depósito fallido",
        save: "Guardar",
        details: "Detalles",
        add: "Añadir",
        confirm: "Confirme",
        remind: "Recuerde",
        success: "Éxito",
        cancel: "Cancelar",
        description: "Descripción",
        copy_success: "¡Copiado con éxito!",
        copy_fail: "¡Copia fallida!",
        submit: "Enviar",
        no_record: "Sin registro",
		countryCode: "Código del país",
        withdraw0: "SOLICITAR",
        withdraw1: "PENDIENTE",
        withdraw2: "ÉXITO",
        withdraw3: "FALLÓ",
        orderNow: "Pedir ahora",
        noBalance: "¡El saldo disponible de la cuenta es insuficiente !",
        needTo: "¡El saldo disponible de la cuenta es insuficiente ! Necesita recargar ",
    },
    home: {
        notice_tit: "Notificación",
        notice_cont: "Bienvenido a la plataforma de compras gratuitas Groupon Mall , gracias por unirse. Cuanto mayor sea el nivel VIP de la plataforma, mayor será la cantidad de pedidos de productos básicos se puede obtener , y cuanto mayor sea la cantidad de comisión , usted puede ganar fácilmente una generosa comisión de 100.000 rupias al mes.",
        today_profit: "Beneficios de hoy",
        yesterday_profit: "Beneficios de ayer",
        accumulated_profit: "Beneficio acumulado",
        historical_deposits: "Depósitos históricos de los usuarios",
        historical_withdraw: "Retirada histórica de usuarios",
        about_investment_income: "Acerca de los rendimientos de las inversiones",
        notice: "Aviso",
        about_us: "Quiénes somos",
        company_qualification: "Cualificación de la empresa",
        cooperative_partner: "Socio cooperador",
    },
    login: {
        login: "Inicio de sesión",
        register: "Regístrese en",
        remember: "Recordar contraseña de la cuenta",
        top: "Obtener código",
        complete_register: "Registro completo",
    },
    form: {
        label_username: "Nombre de usuario",
        place_username: "Introduzca su número de teléfono",
        label_password: "Contraseña",
        place_password: "Introduzca la contraseña",
        label_phone: "Número de teléfono",
        place_phone: "Introduzca su número de teléfono",
        label_verifycode: "Código de verificación",
        place_verifycode: "Código de verificación",
        label_setpassword: "Configuración de la contraseña de inicio de sesión",
        label_crmpassword: "Confirmar contraseña de inicio de sesión",
        place_setpassword: "Confirmar contraseña de inicio de sesión",
        label_invitecode: "Código de invitación",
        place_invitecode: "Código de invitación",
        label_realname: "Nombre real",
        place_realname: "Introduzca su nombre real",
        label_telephone: "Teléfono",
        place_telephone: "Por favor, introduzca el teléfono",
        label_bankname: "Nombre del banco",
        place_bankname: "Introduzca el nombre del banco",
        label_banknumber: "Número de cuenta bancaria",
        place_banknumber: "Introduzca el número de cuenta bancaria",
        label_ifsc: "IFSC",
        place_ifsc: "Introduzca IFSC",
        label_oldpassword: "Contraseña antigua",
        label_newpassword: "Nueva contraseña",
        message_amount: "Introduzca el importe",
        place_deposit_amount: "Importe del depósito",
        place_withdraw_amount: "Introduzca el importe",
        place_payment_channel: "Introduzca el canal de pago",
        label_email: "Correo electrónico",
        place_email: "Introduzca su dirección de correo electrónico",
        label_actualname: "Nombre real",
        place_actualname: "Introduzca el nombre real",
    },
    my: {
        add_bank: "Añadir número de cuenta bancaria",
        bank_account: "Cuenta bancaria",
        change_password: "Cambiar contraseña",
        modify_pay_password: "Modificar la contraseña de pago",
        choose_bank: "Elegir cuenta bancaria",
        deposit_record: "Registro de depósitos",
        withdraw_record: "Registro de retiradas",
        edit_email: "Editar correo electrónico",
        edit_name: "Editar nombre real",
        invite: "Invite a",
        invite_code: "Código de invitación",
        copy_link: "Copiar enlace",
        message: "Mensaje",
        personal_center: "Centro Personal",
        order_list: "Lista de pedidos",
        team_report: "Informe del equipo",
    },
    deposit: {
        deposit_amount: "Importe del depósito",
        account_balance: "Saldo de la cuenta",
        payment_channel: "Canal de pago",
        tip1: "1.La cuenta UPI de la plataforma se cambiará en cualquier momento. Por favor, vaya a la interfaz de recarga para obtener la última cuenta para cada depósito.",
        tip2: "2.Póngase en contacto con su asistente de atención al cliente si tiene problemas.",
        methodDrawer_tit: "Seleccione el banco beneficiario",
    },
    missions: {
        order_hall: "Sala de pedidos",
        commission: "Comisión",
        account_balance: "Saldo de la cuenta",
        commission_today: "La Comisión hoy",
        total_commission: "Comisión total",
        completed_orders: "Pedidos completados",
        locked_orders: "Pedidos bloqueados",
        unfinished_tasks: "Tareas pendientes",
        frozen_amount: "Importe congelado",
        my_team: "Mi equipo",
        order: "Pida",
        match_successfully: "Emparejar con éxito ",
        tip1: "1.Tu nivel de afiliación puede corresponder a 15 misiones individuales.",
        tip2: "2. Si no se confirma el envío del pedido en 60,00 minutos, el sistema congelará el importe del pedido; póngase en contacto con el agente de atención al cliente lo antes posible para tratar la solución.",
    },
    setup: {
        set_up: "Puesta en marcha",
        user_id: "ID de usuario",
        my_name: "Mi nombre",
        actual_name: "Nombre real",
        email: "Correo electrónico",
        my_bank_account_number: "Mi número de cuenta bancaria",
        change_password: "Cambiar contraseña",
        set_modify_payment_password: "Modificar la contraseña de pago",
    },
    team: {
        team_report: "Informe del equipo",
        to: "A",
        searchFor: "BuscarEn",
        team_balance: "Equilibrio del equipo",
        total_team_recharge: "Recarga total del equipo",
        team_commission: "Equipo Comisión",
        team_flow: "Equipo Flow",
        total_team_Withdrawal: "Retirada total del equipo",
        number_of_team_members: "Número de miembros del equipo",
        account_name: "Nombre de la cuenta",
        recommended_number: "Número recomendado",
        registrationTime: "RegistrationTime",
    },
    withdraw: {
        withdraw_amount: "Importe de la retirada",
        all: "Retirada Todos",
        withdraw_bank: "Número de cuenta bancaria de retirada",
        tip1: "①El importe mínimo de retirada es de 10 o más antes de que la retirada pueda realizarse con éxito.",
        tip2: "②5% de comisión deducida de cada retirada.",
        tip3: "③Después de completar la solicitud de retiro, la plataforma procederá en orden, por favor espere pacientemente.",
    },
    order: {
        order_details: "Detalles del pedido",
        order_freeze: "Cuenta atrás de congelación de pedidos",
        product_information: "Información sobre el producto",
        tip: "R@DIANT Lámpara de araña con 3 luces, cubo (negro) Marca R@DIANT",
        order_amount: "Importe del pedido",
        order_information: "Información sobre pedidos",
        get_time: "Conseguir tiempo",
        payment_time: "Plazo de pago",
        order_number: "Número de pedido",
        rebate_information: "Información sobre reembolsos",
        rebate_ratio: "Ratio de descuento",
        rebate_amount: "Importe del reembolso",
        expected_profit: "Beneficio previsto",
        order_record: "Registro de pedidos",
        in_progress: "En curso",
        frozen: "Congelado",
        completed: "Completado",
        order_freeze_countdown: "Cuenta atrás de congelación de pedidos",
        unfreeze: "Descongelar",
    },
    city: {
        albania: "Albania",
        algeria: "Argelia",
	    argentina: "Argentina",
	    armenia:  "Armenia",
	    australia: "Australia",
	    pakistan: "Pakistán",
        austria:  "Austria",
	    bahrain: "Bahrein",
	    belgium: "Bélgica",
	    bosnia_and_Herzegovina: "Bosnia y Herzegovina",
	    brazil: "Brasil",
	    brunei: "Brunei",
	    bulgaria: "Bulgaria",
	    cambodia: "Camboya",
		canada: "Canadá",
		cameroon: "Camerún",
		chile: "Chile",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croacia",
		cyprus: "Chipre",
		czech_Republic: "República Checa",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estonia",
		ethiopia: "Etiopía",
        finland : "Finlandia",
		france: "Francia",
		georgia: "Georgia",
		germany: "Alemania",
		ghana: "Ghana",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong kong, China",
		hungary: "Hungría",
		iceland: "Islandia",
		ireland: "Irlanda",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Irán",
		iraq: "Irak",
		japan: "Japón",
		kazakstan: "Kazajstán",
		kenya: "Kenia",
		korea: "Corea del Sur",
		kuwait: "Kuwait",
		kyrgyzstan:"Kirguistán",
		laos:"Laos",
		latvia:"Letonia",
		lithuania:"Lituania",
		luxembourg:"Luxemburgo",
		macao_China:"Macao, China",
		macedonia:"Macedonia",
		malaysia:"Malasia",
		malta:"Malta",
		mexico:"México",
		moldova:"Moldavia",
		monaco:"Mónaco",
		mongolia:"Mongolia",
		montenegro:"República de Montenegro",
		morocco:"Marruecos",
		myanmar:"Myanmar",
		netherlands:"Países Bajos",
		new_Zealand:"Nueva Zelanda",
		nepal:"Nepal",
		nigeria:"Nigeria",
		norway:"Noruega",
		oman:"Omán",
		palestine:"Palestina",
		panama:"Panamá",
		paraguay:"Paraguay",
		peru:"Perú",
		philippines:"Filipinas",
		poland:"Polonia",
		portugal:"Portugal",
		puerto_Rico:"Puerto Rico",
		qatar:"Qatar",
		romania:"Rumanía",
		russia:"Rusia",
		republic_of_Trinidad_and_Tobago:"República de Trinidad y Tobago",
		rwanda:"Rwanda",
		saudi_Arabia:"Arabia Saudita",
		serbia:"Serbia",
		singapore:"Singapur",
		slovakia:"Eslovaquia",
		slovenia:"Eslovenia",
		south_Africa:"Sudáfrica",
		spain:"España",
		sri_Lanka:"Sri Lanka",
		sweden:"Suecia",
		switzerland:"Suiza",
		taiwan_China:"Taiwán, China",
		tajikistan:"Tayikistán",
		tanzania:"Tanzania",
		thailand:"Tailandia",
		turkey:"Turquía",
		turkmenistan:"Turkmenistán",
		ukraine:"Ucrania",
		united_Arab_Emirates:"Emiratos Árabes Unidos",
		united_Kingdom:"Reino Unido",
		united_States:"Estados Unidos",
		uzbekistan:"Uzbekistán",
		venezuela:"Venezuela",
		vietnam:"Vietnam",
		afghanistan:"Afganistán",
		angola:"Angola",
		azerbaijan:"Azerbaiyán",
		bangladesh:"Bangladesh",
		belarus:"Bielorrusia",
		belize:"Belice",
		benin:"Benin",
		bhutan:"Bhután",
		bolivia:"Bolivia",
		botswana:"Botswana",
		british_Virgin_Islands:"Islas Vírgenes Británicas",
		burkina_Faso:"Burkina Faso",
		burundi:"Burundi",
		cape_Verde:"Cabo Verde",
		cayman_Islands:"Islas Caimán",
		central_African_Republic:"República Centroafricana",
		chad:"Chad",
		comoros:"Comoras",
		the_Republic_of_the_Congo:"Congo (brazzaville)",
		democratic_Republic_of_the_Congo:"República Democrática del Congo (rdc)",
		djibouti:"Djibouti",
		ecuador:"Ecuador",
		el_Salvador:"El Salvador",
		equatorial_Guinea:"Guinea Ecuatorial",
		eritrea:"Eritrea",
		fiji:"Fiji",
		gabon:"Gabón",
		gambia:"Gambia",
		greenland:"Groenlandia",
		guatemala:"Guatemala",
		guinea:"Guinea",
		haiti:"Haití",
		isle_of_Man:"Isla de Man",
		cote_d_Ivoire:"Costa de Marfil",
		jamaica:"Jamaica",
		jordan:"Jordania",
		lebanon:"Líbano",
		lesotho:"Lesotho",
		liberia:"Liberia",
		libya:"Libia",
		madagascar:"Madagascar",
		malawi:"Malawi",
		maldives:"Maldivas",
		mali:"Malí",
		mauritania:"Mauritania",
		mauritius:"Mauricio",
		mozambique:"Mozambique",
		namibia:"Namibia",
		nicaragua:"Nicaragua",
		republic_of_Niger:"Níger",
		north_Korea:"Corea del Norte",
		reunion:"Reunión",
		san_Marino:"San Marino",
		senegal:"Senegal",
		sierra_Leone:"Sierra Leona",
		somalia:"Somalia",
		sudan:"Sudán",
		suriname:"Surinam",
		eswatini:"Swazilandia",
		syria:"Siria",
		togo:"Togo",
		tonga:"Tonga",
		tunisia:"Túnez",
		united_States_Virgin_Islands:"Islas Vírgenes de los Estados Unidos",
		uganda:"Uganda",
		uruguay:"Uruguay",
		vatican:"Vaticano",
		yemen:"Yemen",
		yugoslavia:"Yugoslavia",
		zambia:"Zambia",
		zimbabwe:"Zimbabwe",
		china:"China",
	}



};
