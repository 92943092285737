import Vue from 'vue';
import VueI18n from 'vue-i18n';

import elementEN from 'element-ui/lib/locale/lang/en'
import elementES from 'element-ui/lib/locale/lang/es'
import elementPT from 'element-ui/lib/locale/lang/pt'
import elementDe from 'element-ui/lib/locale/lang/de'

Vue.use(VueI18n);

//导入语言包
import En from '../lang/en.js'; // 英文语言包
import Es from '../lang/es.js'; //
import Pt from '../lang/pt.js'; //
import De from '../lang/de.js'; //


const messages = {
    en: {
        ...elementEN,
        ...En
    },
    es: {
        ...elementES,
        ...Es
    },
    pt: {
        ...elementPT,
        ...Pt
    },
    de: {
        ...elementDe,
        ...De
    },

}

window.localStorage.setItem("lang", 'en')
export default new VueI18n({
    locale: 'en', // set locale 默认显示英文
    fallbackLocale: 'en', //如果语言包没有，则默认从中文中抽取
    messages: messages // set locale messages
});

