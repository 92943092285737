export default {
    common: {
        rechargePic: "Recharger voucher",
        language: "Language",
        walletAddress: "Wallet Address",
        walletType: "Wallet Type",
        addAddress: "Add Address",
        chooseAddress: "Choose Address",
        withdrawAddress: "Withdraw Address",
        levelEight: "Bank card channel freeze",
        selectBank: "Please select a bank account",
        freezeMessage: "Please contact your supervisor",
        completeOrder: "Are you sure you want to complete this order?",
        logout: "Are you sure you want to log out?",
        currency: "USDT",
        deposit: "Deposit",
        withdraw: "Withdraw",
        invite: "Invite",
        balance: "Balance",
        app_name: "Zalando",
        deposit_successful: "Deposit Successful",
        withdraw_successful: "Withdraw Successful",
        deposit_fail: "Deposit Fail",
        save: "Save",
        details: "Details",
        add: "Add",
        confirm: "Confirm",
        remind: "Remind",
        success: "Success",
        cancel: "Cancel",
        description: "Description",
        copy_success: "Successfully copied!",
        copy_fail: "Copy failed!",
        submit: "Submit",
        no_record: "No Record",
		countryCode: "Country code",
        withdraw0: "APPLY",
        withdraw1: "PENDING",
        withdraw2: "SUCCESS",
        withdraw3: "FAILED",
        orderNow: "Order Now",
        noBalance: "The available balance of the account is insufficient !",
        needTo: "The available balance of the account is insufficient ! You need to recharge ",
    },
    home: {
        notice_tit: "Notification",
        notice_cont: "Welcome to the Groupon Mall free shopping platform , thank you for joining. The higher the VIP level of the platform, the higher the amount of commodity orders can be obtained , and the higher the commission amount , you can easily earn a generous commission of 100,000 rupees per month.",
        today_profit: "Today's profit",
        yesterday_profit: "Yesterday's profit",
        accumulated_profit: "Accumulated profit",
        historical_deposits: "Historical User Deposits",
        historical_withdraw: "Historical User Withdraw",
        about_investment_income: "About Investment Income",
        notice: "Notice",
        about_us: "About Us",
        company_qualification: "Company Qualification",
        cooperative_partner: "Cooperative Partner",
    },
    login: {
        login: "Login",
        register: "Register",
        remember: "Remember Account Password",
        top: "Get Code",
        complete_register: "Complete Registration",
    },
    form: {
        label_username: "User Name",
        place_username: "Please Input Phone number",
        label_password: "Password",
        place_password: "Please Enter Password",
        label_phone: "Phone Number",
        place_phone: "Please Input Phone number",
        label_verifycode: "Verification Code",
        place_verifycode: "Verification Code",
        label_setpassword: "Setting Login Password",
        label_crmpassword: "Confirm Login Password",
        place_setpassword: "Confirm Login Password",
        label_invitecode: "Invitation Code",
        place_invitecode: "Invitation Code",
        label_realname: "Real Name",
        place_realname: "Please Enter Real Name",
        label_telephone: "Telephone",
        place_telephone: "Please Enter Telephone",
        label_bankname: "Bank Name",
        place_bankname: "Please Enter Bank Name",
        label_banknumber: "Bank Account Number",
        place_banknumber: "Please Enter Bank Account Number",
        label_ifsc: "IFSC",
        place_ifsc: "Please Enter IFSC",
        label_oldpassword: "Old password",
        label_newpassword: "New Password",
        message_amount: "Please Input amount",
        place_deposit_amount: "Deposit Amount",
        place_withdraw_amount: "Enter Amount",
        place_payment_channel: "Please Input Payment Channel",
        label_email: "E-mail",
        place_email: "Please Enter E-mail",
        label_actualname: "Actual Name",
        place_actualname: "Please Enter Actual Name",
    },
    my: {
        add_bank: "Add Bank Account Number",
        bank_account: "Bank Account",
        change_password: "Change Password",
        modify_pay_password: "Modify Payment Password",
        choose_bank: "Choose Bank Account",
        deposit_record: "Deposit Record",
        withdraw_record: "Withdrawals Record",
        edit_email: "Edit E-mail",
        edit_name: "Edit Actual Name",
        invite: "Invite",
        invite_code: "Invitation Code",
        copy_link: "Copy Link",
        message: "Message",
        personal_center: "Personal Center",
        order_list: "Order List",
        team_report: "Team Report",
    },
    deposit: {
        deposit_amount: "Deposit Amount",
        account_balance: "Account Balance",
        payment_channel: "Payment Channel",
        tip1: "1. The platform wallet address will change at any time. Please enter the recharge interface to obtain the latest information on the wallet address of each deposit.",
        tip2: "2.Please contact your customer assistant if you encounter problems.",
        methodDrawer_tit: "Please select the beneficiary bank",
    },
    missions: {
        order_hall: "Order Hall",
        commission: "Commission",
        account_balance: "Account Balance",
        commission_today: "Commission today",
        total_commission: "Total Commission",
        completed_orders: "Completed orders",
        locked_orders: "Locked orders",
        unfinished_tasks: "Unfinished tasks",
        frozen_amount: "Frozen amount",
        my_team: "My team",
        order: "Order",
        match_successfully: "Match Successfully",
        tip1: "1.Your membership level can match 15 single missions.",
        tip2: "2. If the order is not confirmed to be submitted within 60.00 minutes, the system will freeze the order amount, please contact the customer agent as soon as possible to deal with the solution.",
    },
    setup: {
        set_up: "Set Up",
        user_id: "User ID",
        my_name: "My Name",
        actual_name: "Actual Name",
        email: "E-mail",
        my_bank_account_number: "My Wallet Address",
        change_password: "Change Password",
        set_modify_payment_password: "Set Modify Payment Password",
    },
    team: {
        team_report: "Team Report",
        to: "To",
        searchFor: "SearchFor",
        team_balance: "Team Balance",
        total_team_recharge: "Total Team Recharge",
        team_commission: "Team Commission",
        team_flow: "Team Flow",
        total_team_Withdrawal: "Total Team Withdrawal",
        number_of_team_members: "Number of team members",
        account_name: "Account Name",
        recommended_number: "Recommended Number",
        registrationTime: "registrationTime",
    },
    withdraw: {
        withdraw_amount: "Withdrawal Amount",
        all: "Withdrawl All",
        withdraw_bank: "Withdrawal Bank Account Number",
        tip1: "①The minimum withdrawal amount is 10 or more before the withdrawal can be successful.",
        tip2: "②5% fee deducted from each withdrawal.",
        tip3: "③After completing the withdrawal application, the platform will proceed in order, please wait patiently.",
    },
    order: {
        order_details: "Order Details",
        order_freeze: "Order freeze countdown",
        product_information: "product information",
        tip: "R@DIANT Chandelier with 3 Lights, Cube (Black) Brand R@DIANT",
        order_amount: "Order Amount",
        order_information: "order information",
        get_time: "Get time",
        payment_time: "Payment Time",
        order_number: "Order Number",
        rebate_information: "Rebate Information",
        rebate_ratio: "Rebate Ratio",
        rebate_amount: "Rebate Amount",
        expected_profit: "Expected profit",
        order_record: "Order record",
        in_progress: "In progress",
        frozen: "Frozen",
        completed: "Completed",
        order_freeze_countdown: "Order freeze countdown",
        unfreeze: "Unfreeze",
    },
    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgium",
        bosnia_and_Herzegovina: "Bosnia and Herzegovina",
        brazil: "Brazil",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodia",
        canada: "Canada",
        cameroon: "Cameroon",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Cyprus",
        czech_Republic: "Czech Republic",
        denmark: "Denmark",
        dominican_Republic: "Dominican Republic",
        egypt: "Egypt",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finland",
        france: "France",
        georgia: "Georgia",
        germany: "Germany",
        ghana: "Ghana",
        greece: "Greece",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungary",
        iceland: "Iceland",
        ireland: "Ireland",
        italy: "Italy",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Japan",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        macao_China: "Macao_China",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Morocco",
        myanmar: "Myanmar",
        netherlands: "Netherlands",
        new_Zealand: " New_Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norse",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Russia",
        republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Saudi Arabia",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "South Africa",
        spain: "Spain",
        sri_Lanka: "Sri Lanka",
        sweden: "Sweden",
        switzerland: "Switzerland",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "United Arab Emirates",
        united_Kingdom: "United Kingdom",
        united_States: " United_States",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "British Virgin Islands",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Central African Republic",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
        democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Equatorial Guinea",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Isle_of_Man",
        cote_d_Ivoire: "Cote_d_Ivoir",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libya",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republic_of_Niger",
        north_Korea: " North_Korea",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "United States Virgin Islands",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
     }



};
