export default {
    common: {
        rechargePic: "Recharger voucher",
        completeOrder: "Tem a certeza de que pretende concluir esta encomenda?",
        logout: "Tem a certeza de que pretende terminar a sessão?",
        currency: "USDT",
        deposit: "Depósito",
        withdraw: "Retirada",
        invite: "Convite",
        balance: "Equilíbrio",
        app_name: "Zalando",
        deposit_successful: "Depósito bem sucedido",
        withdraw_successful: "Retirada com êxito",
        deposit_fail: "Depósito falhado",
        save: "Guardar",
        details: "Detalhes",
        add: "Adicionar",
        confirm: "Confirmar",
        remind: "Relembrar",
        success: "Sucesso",
        cancel: "Cancelar",
        description: "Descrição",
        copy_success: "Copiado com sucesso!",
        copy_fail: "A cópia falhou!",
        submit: "Enviar",
        no_record: "Sem registo",
		countryCode: "Código do país",
        withdraw0: "CANDIDATURA",
        withdraw1: "PENDENTE",
        withdraw2: "SUCESSO",
        withdraw3: "REPROVADO",
        orderNow: "Encomendar agora",
        noBalance: "O saldo disponível da conta é insuficiente !",
        needTo: "O saldo disponível da conta é insuficiente! É necessário efetuar um carregamento ",
    },
    home: {
        notice_tit: "Notificação",
        notice_cont: "Bem-vindo à plataforma de compras gratuitas Groupon Mall, obrigado por aderir. Quanto mais elevado for o nível VIP da plataforma, maior será a quantidade de encomendas de mercadorias que podem ser obtidas, e maior será o montante da comissão, pode facilmente ganhar uma generosa comissão de 100.000 rupias por mês.",
        today_profit: "O lucro de hoje",
        yesterday_profit: "Lucro de ontem",
        accumulated_profit: "Lucro acumulado",
        historical_deposits: "Histórico dos depósitos dos utilizadores",
        historical_withdraw: "Levantamento histórico do utilizador",
        about_investment_income: "Sobre o rendimento do investimento",
        notice: "Aviso",
        about_us: "Sobre nós",
        company_qualification: "Qualificação da empresa",
        cooperative_partner: "Parceiro de cooperação",
    },
    login: {
        login: "Iniciar sessão",
        register: "Registo",
        remember: "Lembrar a palavra-passe da conta",
        top: "Obter código",
        complete_register: "Registo completo",
    },
    form: {
        label_username: "Nome do utilizador",
        place_username: "Introduza o número de telefone",
        label_password: "Palavra-passe",
        place_password: "Introduzir palavra-passe",
        label_phone: "Número de telefone",
        place_phone: "Introduza o número de telefone",
        label_verifycode: "Código de verificação",
        place_verifycode: "Código de verificação",
        label_setpassword: "Definir a palavra-passe de início de sessão",
        label_crmpassword: "Confirmar a palavra-passe de acesso",
        place_setpassword: "Confirmar a palavra-passe de acesso",
        label_invitecode: "Código do convite",
        place_invitecode: "Código do convite",
        label_realname: "Nome verdadeiro",
        place_realname: "Introduzir o nome verdadeiro",
        label_telephone: "Telefone",
        place_telephone: "Por favor, introduza o telefone",
        label_bankname: "Nome do banco",
        place_bankname: "Introduza o nome do banco",
        label_banknumber: "Número da conta bancária",
        place_banknumber: "Introduza o número da conta bancária",
        label_ifsc: "IFSC",
        place_ifsc: "Introduzir IFSC",
        label_oldpassword: "Palavra-passe antiga",
        label_newpassword: "Nova palavra-passe",
        message_amount: "Introduzir o montante",
        place_deposit_amount: "Montante do depósito",
        place_withdraw_amount: "Introduzir o montante",
        place_payment_channel: "Introduza o canal de pagamento",
        label_email: "Correio eletrónico",
        place_email: "Introduzir e-mail",
        label_actualname: "Nome atual",
        place_actualname: "Introduza o nome real",
    },
    my: {
        add_bank: "Adicionar número de conta bancária",
        bank_account: "Conta bancária",
        change_password: "Alterar a palavra-passe",
        modify_pay_password: "Modificar a palavra-passe de pagamento",
        choose_bank: "Selecionar conta bancária",
        deposit_record: "Registo de depósito",
        withdraw_record: "Registo de retiradas",
        edit_email: "Editar e-mail",
        edit_name: "Editar nome real",
        invite: "Convite",
        invite_code: "Código do convite",
        copy_link: "Copiar ligação",
        message: "Mensagem",
        personal_center: "Centro pessoal",
        order_list: "Lista de encomendas",
        team_report: "Relatório da equipa",
    },
    deposit: {
        deposit_amount: "Montante do depósito",
        account_balance: "Saldo da conta",
        payment_channel: "Canal de pagamento",
        tip1: "1. a conta UPI da plataforma pode ser alterada em qualquer altura. Por favor, vá para a interface de recarga para obter a conta mais recente para cada depósito.",
        tip2: "2. Contactar o assistente do cliente se tiver problemas.",
        methodDrawer_tit: "Selecionar o banco beneficiário",
    },
    missions: {
        order_hall: "Sala de encomendas",
        commission: "Comissão",
        account_balance: "Saldo da conta",
        commission_today: "A Comissão hoje",
        total_commission: "Total da Comissão",
        completed_orders: "Encomendas concluídas",
        locked_orders: "Ordens bloqueadas",
        unfinished_tasks: "Tarefas inacabadas",
        frozen_amount: "Montante congelado",
        my_team: "A minha equipa",
        order: "Encomendar",
        match_successfully: "Corresponder com êxito",
        tip1: "1. O teu nível de inscrição pode corresponder a 15 missões individuais.",
        tip2: "2. Se a encomenda não for confirmada no prazo de 60 minutos, o sistema congelará o montante da encomenda. Contacte o agente do cliente o mais rapidamente possível para encontrar uma solução.",
    },
    setup: {
        set_up: "Configurar",
        user_id: "ID do utilizador",
        my_name: "O meu nome",
        actual_name: "Nome atual",
        email: "Correio eletrónico",
        my_bank_account_number: "Número da minha conta bancária",
        change_password: "Alterar a palavra-passe",
        set_modify_payment_password: "Definir a palavra-passe de modificação do pagamento",
    },
    team: {
        team_report: "Relatório da equipa",
        to: "Para",
        searchFor: "PesquisarPara",
        team_balance: "Equilíbrio da equipa",
        total_team_recharge: "Recarga total da equipa",
        team_commission: "Equipa Comissão",
        team_flow: "Fluxo da equipa",
        total_team_Withdrawal: "Retirada total da equipa",
        number_of_team_members: "Número de membros da equipa",
        account_name: "Nome da conta",
        recommended_number: "Número recomendado",
        registrationTime: "registoTempo",
    },
    withdraw: {
        withdraw_amount: "Montante da retirada",
        all: "Retirada Todos",
        withdraw_bank: "Número da conta bancária de levantamento",
        tip1: "①O montante mínimo de levantamento é de 10 ou mais para que o levantamento possa ser bem sucedido.",
        tip2: "②Taxa de 5% deduzida de cada levantamento.",
        tip3: "③Após completar o pedido de levantamento, a plataforma irá proceder por ordem, por favor aguarde pacientemente.",
    },
    order: {
        order_details: "Detalhes da encomenda",
        order_freeze: "Contagem decrescente do congelamento de encomendas",
        product_information: "Informações sobre o produto",
        tip: "R@DIANT Lustre com 3 luzes, Cube (Preto) Marca R@DIANT",
        order_amount: "Montante da encomenda",
        order_information: "Informações sobre a encomenda",
        get_time: "Obter tempo",
        payment_time: "Prazo de pagamento",
        order_number: "Número de encomenda",
        rebate_information: "Informações sobre o desconto",
        rebate_ratio: "Rácio de desconto",
        rebate_amount: "Montante do desconto",
        expected_profit: "Lucro esperado",
        order_record: "Registo de encomenda",
        in_progress: "Em curso",
        frozen: "Congelado",
        completed: "Concluído",
        order_freeze_countdown: "Contagem decrescente do congelamento de encomendas",
        unfreeze: "Descongelar",
    },
    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgium",
        bosnia_and_Herzegovina: "Bosnia and Herzegovina",
        brazil: "Brazil",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodia",
        canada: "Canada",
        cameroon: "Cameroon",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Cyprus",
        czech_Republic: "Czech Republic",
        denmark: "Denmark",
        dominican_Republic: "Dominican Republic",
        egypt: "Egypt",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finland",
        france: "France",
        georgia: "Georgia",
        germany: "Germany",
        ghana: "Ghana",
        greece: "Greece",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungary",
        iceland: "Iceland",
        ireland: "Ireland",
        italy: "Italy",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Japan",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        macao_China: "Macao_China",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Morocco",
        myanmar: "Myanmar",
        netherlands: "Netherlands",
        new_Zealand: " New_Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norse",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Russia",
        republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Saudi Arabia",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "South Africa",
        spain: "Spain",
        sri_Lanka: "Sri Lanka",
        sweden: "Sweden",
        switzerland: "Switzerland",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "United Arab Emirates",
        united_Kingdom: "United Kingdom",
        united_States: " United_States",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "British Virgin Islands",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Central African Republic",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
        democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Equatorial Guinea",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Isle_of_Man",
        cote_d_Ivoire: "Cote_d_Ivoir",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libya",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republic_of_Niger",
        north_Korea: " North_Korea",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "United States Virgin Islands",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
     }



};
